/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type HealthCareProviderDetailFragment = {
  __typename?: 'HealthCareProvider';
  id: any;
  companyName: string;
  doctorName: string;
  idNumber: string;
  web: string;
  phone: string;
  email: string;
  meAllowedOperations: Array<Types.PermissionOperationChoices>;
  address?: {
    __typename?: 'HealthCareProviderAddress';
    id: any;
    street: string;
    city: string;
    countryCode?: Types.CountryCodeChoices | null;
    zipCode: string;
    state: string;
  } | null;
};

export type HealthCareProviderDetailQueryVariables = Types.Exact<{
  healthCareProviderDetailId: Types.Scalars['HealthCareProviderID']['input'];
}>;

export type HealthCareProviderDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'HealthCareProvider';
        id: any;
        companyName: string;
        doctorName: string;
        idNumber: string;
        web: string;
        phone: string;
        email: string;
        meAllowedOperations: Array<Types.PermissionOperationChoices>;
        address?: {
          __typename?: 'HealthCareProviderAddress';
          id: any;
          street: string;
          city: string;
          countryCode?: Types.CountryCodeChoices | null;
          zipCode: string;
          state: string;
        } | null;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const HealthCareProviderDetailFragmentDoc = gql`
  fragment HealthCareProviderDetail on HealthCareProvider {
    id
    companyName
    doctorName
    idNumber
    address {
      id
      street
      city
      countryCode
      zipCode
      state
    }
    web
    phone
    email
    meAllowedOperations
  }
`;
export const HealthCareProviderDetailDocument = gql`
  query HealthCareProviderDetail($healthCareProviderDetailId: HealthCareProviderID!) {
    object: healthCareProviderDetail(id: $healthCareProviderDetailId) {
      ... on ObjectDoesNotExistResponse {
        objectId
      }
      ... on HealthCareProvider {
        ...HealthCareProviderDetail
      }
    }
  }
  ${HealthCareProviderDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class HealthCareProviderDetailGQL extends Apollo.Query<
  HealthCareProviderDetailQuery,
  HealthCareProviderDetailQueryVariables
> {
  override document = HealthCareProviderDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
